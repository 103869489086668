import {shopCarousel} from '../carousels';

export default function () {
    document.addEventListener('livewire:init', () => {
        Livewire.on('livewire-carousel-loaded', (event) => {
            setTimeout(function () {
                const shopCarouselItem = document.querySelector('#' + event[0]);
                shopCarousel(shopCarouselItem);
            });
        });
    });
}
