import jQuery from "jquery";
import { Modal } from "bootstrap";

export default function () {
    (function ($) {
        $('#selectCountry_2').on('change', function () {
            $.ajax({
                url: provincesUrl + "?country_code=" + $(this).val(),
                success: function (data) {
                    if (data) {
                        $('#selectProvince').parent().removeClass('d-none'); //.attr('required', true)
                        $('#selectCity').parent().removeClass('d-none'); //.attr('required', true)
                        $('#selectProvinceManual').parent().addClass('d-none'); //.removeAttr('required')
                        $('#selectCityManual').parent().addClass('d-none'); //.removeAttr('required')
                        $('#selectProvince').html("<option value=''>" + chooseOneTrans + "</option>" + data);
                        $('#selectCity').html("<option value=''>" + chooseOneTrans + "</option>");

                        let oldValue = $('#selectProvince').data("value");
                        $('#selectProvince').val(oldValue).trigger("change");
                    } else {
                        $('#selectProvince').parent().addClass('d-none'); //.removeAttr('required')
                        $('#selectCity').parent().addClass('d-none'); //.removeAttr('required')
                        $('#selectProvinceManual').parent().removeClass('d-none'); //.attr('required', true)
                        $('#selectCityManual').parent().removeClass('d-none'); //.attr('required', true)
                    }
                },
                error: function (data) {

                }
            })
        });
        $('#selectCountry_2').trigger('change');

        $('#selectCountry_3').on('change', function () {
            $.ajax({
                url: provincesUrl + "?country_code=" + $(this).val(),
                success: function (data) {
                    if (data) {
                        $('#selectProvince_2').parent().removeClass('d-none'); //.attr('required', true)
                        $('#selectProvince_2Manual').parent().addClass('d-none'); //.removeAttr('required')
                        $('#selectProvince_2').html("<option value=''>" + chooseOneTrans + "</option>" + data);
                        let oldValue = $('#selectProvince_2').data("value");
                        $('#selectProvince_2').val(oldValue).trigger("change");
                    } else {
                        $('#selectProvince_2').parent().addClass('d-none'); //.removeAttr('required')
                        $('#selectProvince_2Manual').parent().removeClass('d-none'); //.attr('required', true)
                    }
                },
                error: function (data) {

                }
            })
        });
        $('#selectCountry_3').trigger('change');

        $('#selectProvince').on('change', function () {
            $.ajax({
                url: citiesUrl + "?province_code=" + $(this).val(),
                success: function (data) {
                    if (data != "") {
                        $('#selectCity').parent().removeClass('d-none'); //.attr('required', true)
                        $('#selectCityManual').parent().addClass('d-none'); //.removeAttr('required')
                        $('#selectCity').html("<option value=''>" + chooseOneTrans + "</option>" + data);
                        let oldValue = $('#selectCity').data("value");
                        $('#selectCity').val(oldValue);
                    } else {
                        $('#selectCity').parent().addClass('d-none'); //.removeAttr('required')
                        $('#selectCityManual').parent().removeClass('d-none'); //.attr('required', true)
                    }
                },
                error: function (data) {

                }
            })
        });
        $('#selectProvince').trigger('change');

        $(document).on('click', '#account-deletion-request', function (e) {
            e.preventDefault();
            const href = $(this).attr('href');
            const bsdeletionDeleteModal = Modal.getOrCreateInstance('#delete-account');
            const bsdeletionConfirmationModal = Modal.getOrCreateInstance('#delete-account-confirmation');

            $.ajax({
                url: href,
                type: 'POST',
                data: {
                    _token: $('meta[name="csrf-token"]').attr('content')
                },
                success: function (response) {
                    if (response.exit_code !== 1) {
                        $('#delete-account-confirmation-description').html('<span style="color: red;">' + response.message + '</span>');
                    } else {
                        $('#delete-account-confirmation-description').html(response.message);
                    }
                },
                error: function (xhr, status, error) {
                    // Azione in caso di errore
                    // Cambio la descrizione della modale
                    $('#delete-account-confirmation-description').html('<span style="color: red;">Error: ' + error + '</span>');
                }
            }).always(function () {
                // Nascondo la modale di richiesta, mostro la modale di conferma
                $("#delete-account").on('hidden.bs.modal', function () {
                    bsdeletionConfirmationModal.show();
                });
                bsdeletionDeleteModal.hide();
            });
        });
    })(jQuery);
}
