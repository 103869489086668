import queryString from 'query-string';
import {initDefaultSelect} from "./selects";

export default function () {
}
export const StoreLocator = {
    map: null,
    cluster: null,
    markers: [],
    firstLoad: true,
    alpine: null,
    timeout: null,
    searchPlace: false,

    init: function () {
        this.alpine = Alpine.store("sl_global");
        this.initAlpine();
        this.initMap();
        this.initEvents();
        this.initAutocomplete();

        this.firstLoad = false;
        this.alpine.setLoaded();
    },

    initMap: async function () {

        let {region, province} = this.alpine.filters;

        let params = await this.geocodeLocation(province, region, siteCountry.name);

        if (this.alpine.leftSide === 'details') {
            const {latitude, longitude} = this.alpine.store;

            params = {
                center: new google.maps.LatLng(parseFloat(latitude), parseFloat(longitude)),
                zoom: 14
            }
        }

        this.map = await new google.maps.Map(document.getElementById("googleMap"), {
            center: new google.maps.LatLng(51.508742, -0.12085),
            zoom: 8,
            minZoom: 6,
            mapTypeControl: false,
            streetViewControl: false,
            styles: this.googleMapStyle,
            ...params
        });

        let self = this;

        self.map.addListener("idle", () => self.onMapMove());
        this.getLocation();
    },

    //this will catch all alpine events
    initAlpine: function () {

        //this will remove all server side renderd elements and alpine will start working
        $('.ssr-element').remove();

        var self = this;

        Alpine.effect(() => {

            if (!self.alpine.isProductDetails) {
                if (self.alpine.store && typeof self.alpine.store.slug !== 'undefined') {
                    self.generateUrl('store');
                }

                if (self.alpine.leftSide === 'list' && !self.firstLoad) {
                    self.generateUrl();
                }
            }

            // if (self.alpine.leftSide === 'list' && self.alpine.prevLeftSide === 'details') {
            //     //primo caricamento ?
            //     self.onMapMove();
            // }

        });
    },

    generateUrl: async function (type = null) {

        if (this.firstLoad) {
            return;
        }

        let self = this;
        let routeUrl = currentRouteUrl + "/" + currentRoutePath;

        let region = '';
        let province = '';
        let store = '';

        if (this.alpine.withFilters) {
            region = parseJsonSafe(this.alpine.filters.region)?.name ?? '-';
            province = parseJsonSafe(this.alpine.filters.province)?.name ?? '';

            if (this.firstLoad) {
                region = this.alpine.filters.region || '-';
                province = this.alpine.filters.province || '-';
            }
        }

        if (type === 'store' && typeof self.alpine.store.slug !== 'undefined' && self.alpine.leftSide === 'details') {

            region = '-';
            province = '-';

            if (self.alpine.store?.region?.length) {
                region = self.alpine.store?.region;
            }
            if (self.alpine.store?.province?.length) {
                province = self.alpine.store?.province
            }

            const geocoder = new google.maps.Geocoder();
            const latlng = new google.maps.LatLng(
                self.alpine.store?.latitude,
                self.alpine.store?.longitude
            );

            var geoRes;

            await geocoder.geocode(
                {
                    location: latlng,
                },
                function (results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        if (results[1]) {
                            geoRes = self.getGeocodeResults(results);
                        } else {
                            console.error("No results found");
                        }
                    } else {
                        console.error("Geocoder failed due to: " + status);
                    }
                }
            );

            if (geoRes?.region) {
                region = geoRes?.region?.long_name;
                self.alpine.store.region = region;
            }

            if (geoRes?.province) {
                province = (geoRes?.province?.long_name.replace('Province of', '').replace('Metropolitan City of', '').replace('Provincia di', '')).trim();
                self.alpine.store.province = province;
            }

            store = self.alpine.store.slug;

            await self.map.panTo(new google.maps.LatLng(
                self.alpine.store.latitude,
                self.alpine.store.longitude
            ))

            if (self.map.zoom < 14) {
                await self.map.setZoom(14);
            }

            self.placeMarkers([{...self.alpine.store}]);
        }

        self.alpine.setBreadcrumb({
            region,
            regionSlug: createSlug(region),
            province,
            provinceSlug: createSlug(province)
        });

        if (!this.alpine.isProductDetails) {
            let replacedUrl = routeUrl.replace('{region?}', createSlug(region)).replace('{province?}', createSlug(province)).replace('{store?}', createSlug(store));
            replacedUrl = replacedUrl.replace(/(?<!https?:)\/+(?!\/)/g, '/'); //Remove repeted slashes
            history.pushState(null, null, replacedUrl);
        }

    },

    initEvents: function () {
        let self = this;
        const provinceSelect = $('#filterProvinciaSelect')

        const disableProvinceSelect = () => provinceSelect.attr('disabled', 'disabled');
        const activateProvinceSelect = () => provinceSelect.removeAttr('disabled');
        const reinitProvinceSelect = () => {
            provinceSelect.select2('destroy');
            initDefaultSelect()
        }
        disableProvinceSelect()

        $('#targetCurrLocation').on('click', () => this.getLocation());
        $('.trigger-filter').on('click', () => this.applyFilter());
        $('#productSearchInput').on('change', () => this.applyFilter());
        $('#filterRegioneSelect').on('change', function () {
            reinitProvinceSelect()
            self.alpine.filters.region = this.value;
            if (this.value === "") {
                disableProvinceSelect()
            } else {
                activateProvinceSelect()
            }
        });
        provinceSelect.on('change', function () {
            self.alpine.filters.province = this.value;
        });

        if (self.alpine.store?.id && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(({coords: {latitude, longitude}}) => {
                self.alpine.store.distance = calcKmBetweenPoints(latitude, longitude, parseFloat(self.alpine.store.latitude), parseFloat(self.alpine.store.longitude));
            });

        }

        if (self.alpine.withFilters) {

            setTimeout(() => {

                if (this.alpine.filters.region.length > 0) {

                    var regionOption = $("#filterRegioneSelect option").filter(function () {
                        return createSlug($(this).text()).includes(createSlug(self.alpine.filters.region));
                    });

                    if (regionOption.length > 0) {
                        regionOption.prop('selected', true);
                        self.alpine.filters.region = regionOption.val();
                        $("#filterRegioneSelect").trigger('change');
                    } else {
                        self.alpine.withFilters = false;
                    }

                }

                if (this.alpine.filters.province.length > 0) {

                    setTimeout(() => {

                        var provinceOption = $("#filterProvinciaSelect option").filter(function () {
                            return createSlug($(this).text()).includes(createSlug(self.alpine.filters.province));
                        });

                        if (provinceOption.length > 0) {
                            provinceOption.prop('selected', true);
                            self.alpine.filters.province = provinceOption.val();
                            $("#filterProvinciaSelect").trigger('change');
                        }

                    }, 1000);
                }

            }, 1000);
        }
    },

    applyFilter: async function () {
        StoreLocator.searchPlace = true;

        if (this.alpine.withFilters) {
            let {region} = this.alpine.filters;
            let {province} = this.alpine.filters;

            let params = await this.geocodeLocation(province ?? '', region, siteCountry.name);

            this.map.setCenter(params.center);
            this.map.setZoom(params.zoom);

        }

        this.generateUrl('filter');
        this.getViewportAjaxData()
    },

    initAutocomplete: function () {

        const input = document.getElementById("mapSearchInput");
        const searchBox = new google.maps.places.SearchBox(input);
        const self = this;

        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();

            if (places.length === 0) {
                return;
            }

            const bounds = new google.maps.LatLngBounds();

            places.forEach((place) => {
                if (!place.geometry || !place.geometry.location) {
                    console.log("Returned place contains no geometry");
                    return;
                }
                if (place.geometry.viewport) {
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });

            self.map.fitBounds(bounds);
            if (self.map.zoom > 11) {
                self.map.setZoom(11);
            }

            self.searchPlace = true;
        });
    },

    getLocation: function () {
        var self = this;
        self.searchPlace = true;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(({coords: {latitude, longitude}}) => {

                self.map.setCenter({
                    lat: parseFloat(latitude),
                    lng: parseFloat(longitude)
                });

                self.map.setZoom(12);

            }, (error) => {
                jQuery('#targetCurrLocation').hide();
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        console.log("User denied the request for Geolocation.");
                        break;
                    case error.POSITION_UNAVAILABLE:
                        console.log("Location information is unavailable.");
                        break;
                    case error.TIMEOUT:
                        console.log("The request to get user location timed out.");
                        break;
                    case error.UNKNOWN_ERROR:
                        console.log("An unknown error occurred.");
                        break;
                }
            });
        } else {
            jQuery('#targetCurrLocation').hide();
            console.log("Geolocation is not supported by this browser.");
        }
    },
    getUserLocation: function () {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        resolve(position.coords);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            } else {
                reject(new Error("Geolocation is not supported by this browser."));
            }
        });
    },
    geocodeLocation: async function (city, state, country) {
        let result = {};
        let self = this;

        try {
            if (!city && !state && country == 'Rest of the World') {
                let userCoords = await this.getUserLocation();
                result = {
                    center: {
                        lat: userCoords.latitude,
                        lng: userCoords.longitude
                    },
                    zoom: 12
                };
            } else {
                let geocoder = new google.maps.Geocoder();
                let address = city + ', ' + state + ', ' + country;

                await geocoder.geocode({'address': address}, function (results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {
                        var bounds = results[0].geometry.viewport;

                        result = {
                            center: {
                                lat: results[0].geometry.location.lat(),
                                lng: results[0].geometry.location.lng()
                            },
                            zoom: self.getZoomLevel(bounds)
                        };

                    } else {
                        console.log('Geocode was not successful for the following reason: ' + status);
                    }
                });
            }
        } catch (error) {
            console.error("Error retrieving user location:", error);
        }

        return Promise.resolve(result);
    },
    getZoomLevel: function (bounds) {
        let GLOBE_WIDTH = 256;
        let southWest = bounds.getSouthWest();
        let northEast = bounds.getNorthEast();

        let lngDifference = Math.abs(northEast.lng() - southWest.lng());
        let latDifference = Math.abs(northEast.lat() - southWest.lat());

        let mapWidth = $('#googleMap').width() || window.innerWidth;
        let mapHeight = $('#googleMap').height() || window.innerHeight;

        let zoomWidth = Math.log(mapWidth * 360 / lngDifference / GLOBE_WIDTH) / Math.LN2;
        let zoomHeight = Math.log(mapHeight * 180 / latDifference / GLOBE_WIDTH) / Math.LN2;

        // Calculate the maximum zoom level based on the width and height
        let zoomLevel = Math.min(zoomWidth, zoomHeight);

        return Math.round(zoomLevel);
    },

    onMapMove: function () {
        if (this.alpine.leftSide === 'list' && this.alpine.prevLeftSide === 'details') {
            this.alpine.prevLeftSide = 'list';
        }

        this.getViewportAjaxData();
    },

    getViewportCords: function () {

        var bounds = this.map.getBounds();
        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();

        return {
            latMin: sw.lat(),
            latMax: ne.lat(),
            lngMin: sw.lng(),
            lngMax: ne.lng(),
        }
    },

    getViewportAjaxData: function () {
        this.alpine.setLoading();
        this.findStoreClosestShop();
    },

    findStoreClosestShop: function () {
        var filters = {};

        if (this.alpine.withFilters) {
            filters = this.alpine.filters
        }
        $.ajax({
            url: getStoresUrl,
            method: "GET",
            data: {
                cords: StoreLocator.getViewportCords(),
                productSearchInput: document.getElementById('productSearchInput').value,
                filters,
            },
            success: function (newResults) {
                //if (newResults.length > 0) {
                StoreLocator.placeMarkers(newResults);
                //} else
                StoreLocator.alpine.currentPage = 1;
                StoreLocator.alpine.totalPages = Math.ceil(newResults.length / 10);

                if (newResults.length > 0) {
                    StoreLocator.searchPlace = false;
                    StoreLocator.formatLocations(newResults);
                } else if (StoreLocator.searchPlace) {
                    if (StoreLocator.map.minZoom < StoreLocator.map.getZoom()) {
                        StoreLocator.map.setZoom(StoreLocator.map.getZoom() - 1);
                    } else {
                        StoreLocator.searchPlace = false;
                    }
                }
                // StoreLocator.alpine.setStores();
                // StoreLocator.alpine.setLoading(false);
            },
            error: () => {
                // StoreLocator.formatLocations(results);
            }
        });
    },
    paginateStoreResults: function () {
        var filters = {};

        if (this.alpine.withFilters) {
            filters = this.alpine.filters
        }

        $.ajax({
            url: getStoresUrl,
            method: "GET",
            data: {
                cords: StoreLocator.getViewportCords(),
                productSearchInput: document.getElementById('productSearchInput').value,
                limit: 10,
                filters,
                page: StoreLocator.alpine.currentPage,
            },
            success: function (newResults) {
                StoreLocator.alpine.totalPages = newResults.last_page;

                if (newResults.data.length > 0) {
                    StoreLocator.searchPlace = false;
                    StoreLocator.formatLocations(newResults.data);
                } else if (StoreLocator.searchPlace) {
                    if (StoreLocator.map.minZoom < StoreLocator.map.getZoom()) {
                        StoreLocator.map.setZoom(StoreLocator.map.getZoom() - 1);
                    } else {
                        StoreLocator.searchPlace = false;
                    }

                }
                StoreLocator.alpine.setStores();
                StoreLocator.alpine.setLoading(false);
            },
            error: () => {
                // StoreLocator.formatLocations(results);
            }
        });
    },
    getGeocodeResults: function (results) {
        let res = {};
        for (let i = 0; i < results.length; i++) {
            for (let j = 0; j < results[i].address_components.length; j++) {
                const typesObj = results[i].address_components[j].types;

                const {types, ...otherData} =
                    results[i].address_components[j];

                if (typesObj.includes("country")) {
                    res["country"] = otherData;
                }

                if (typesObj.includes("administrative_area_level_1")) {
                    res["region"] = otherData;
                }

                if (typesObj.includes("administrative_area_level_2")) {
                    res["province"] = otherData;
                }

                if (typesObj.includes("locality")) {
                    res["city"] = otherData;
                }
            }
        }
        return res;
    },

    // we will format and provide
    formatLocations: function (results) {
        results = results.slice(0, 10);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(({coords: {latitude, longitude}}) => {
                let formatedData = results.map((result) => ({
                    ...result,
                    distance: calcKmBetweenPoints(latitude, longitude, result.latitude, result.longitude)
                }));
                formatedData = formatedData.sort((a, b) => {
                    return ((a.distance < b.distance) ? -1 : ((a.distance > b.distance) ? 1 : 0));
                })
                this.setupLocations(formatedData);
            }, (error) => {
                this.setupLocations(results)
            });
        } else {
            this.setupLocations(results);
        }
    },

    setupLocations: function (res) {
        let results = res;

        if (this.alpine.store?.id && this.alpine.isDetails) {
            results = [{...this.alpine.store}];
        }

        this.alpine.setStores(results);
        this.alpine.setLoading(false);
    },

    placeMarkers: function (results) {
        for (let i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(null);
        }

        this.markers = results.map((marker, index) =>
            this.renderMarker(marker, index)
        );
        this.cluster?.clearMarkers();
        this.cluster = new markerClusterer.MarkerClusterer({
            markers: this.markers,
            map: this.map,
            algorithmOptions: {
                maxZoom: 14,
            },
            // algorithm: new markerClusterer.GridAlgorithm({ gridSize: 21 }),
            renderer: {
                render: ({count, position}) => {
                    return new google.maps.Marker({
                        position,
                        icon: {
                            url: "/assets/elica/images/ico-marker.svg",
                        },
                        label: {
                            className: 'marker-cluster-number',
                            text: String(count),
                            color: "#fff",
                            fontSize: "16px",
                            fontWeight: "500",
                            fontFamily: "Elica Favorit, Helvetica, sans-serif",
                        },
                        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                    });
                }
            },
            // styles: [
            //
            // ]
        });

        this.alpine.setLoading(false);
    },
    renderMarker: function (element, index) {
        const {name, latitude, longitude, address, telephone, type, ...other} =
            element;
        var self = this;
        let icon = 'ico-marker-shop.svg';


        if (type === 'distributore') {
            icon = 'ico-marker-distributor.svg';
        }

        var marker = new google.maps.Marker({
            position: {
                lat: parseFloat(latitude),
                lng: parseFloat(longitude)
            },
            map: this.map,
            title: name,
            icon: `/assets/elica/images/${icon}`,
        });

        marker.addListener("click", async () => {
            if ((self.alpine.store?.id === element?.id) && self.alpine.isDetails) {
                self.alpine.showList();
            } else {
                self.alpine.showDetails(element);
            }
        });

        return marker;
    },

    page: function () {
        // window.onload = function() {
        //     if ($(".page-store-locator").length == 1) {
        window.addEventListener('GoogleMapReady', function () {
            StoreLocator.init();
        });
        // this.init();
        // }
        // }.bind(this);

    },

    googleMapStyle: [
        {
            "featureType": "all",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#f2f2f2"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#686868"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#f2f2f2"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 45
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "lightness": "-22"
                },
                {
                    "visibility": "on"
                },
                {
                    "color": "#b4b4b4"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "saturation": "-51"
                },
                {
                    "lightness": "11"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text",
            "stylers": [
                {
                    "saturation": "3"
                },
                {
                    "lightness": "-56"
                },
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "lightness": "-52"
                },
                {
                    "color": "#9094a0"
                },
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "weight": "6.13"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "weight": "1.24"
                },
                {
                    "saturation": "-100"
                },
                {
                    "lightness": "-10"
                },
                {
                    "gamma": "0.94"
                },
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road.highway.controlled_access",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#b4b4b4"
                },
                {
                    "weight": "5.40"
                },
                {
                    "lightness": "7"
                }
            ]
        },
        {
            "featureType": "road.highway.controlled_access",
            "elementType": "labels.text",
            "stylers": [
                {
                    "visibility": "simplified"
                },
                {
                    "color": "#231f1f"
                }
            ]
        },
        {
            "featureType": "road.highway.controlled_access",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "visibility": "simplified"
                },
                {
                    "color": "#595151"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "lightness": "-16"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#d7d7d7"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text",
            "stylers": [
                {
                    "color": "#282626"
                },
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": "-41"
                },
                {
                    "lightness": "-41"
                },
                {
                    "color": "#2a4592"
                },
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "weight": "1.10"
                },
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "lightness": "-16"
                },
                {
                    "weight": "0.72"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "lightness": "-37"
                },
                {
                    "color": "#2a4592"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "color": "#eeed6a"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "visibility": "off"
                },
                {
                    "color": "#0a0808"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#b7e4f4"
                },
                {
                    "visibility": "on"
                }
            ]
        }
    ]
};

function calcKmBetweenPoints(lat1, lon1, lat2, lon2) {
    const earthRadiusKm = 6371;

    const lat1Rad = degreesToRadians(lat1);
    const lon1Rad = degreesToRadians(lon1);
    const lat2Rad = degreesToRadians(lat2);
    const lon2Rad = degreesToRadians(lon2);

    const deltaLat = lat2Rad - lat1Rad;
    const deltaLon = lon2Rad - lon1Rad;

    const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(lat1Rad) * Math.cos(lat2Rad) *
        Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadiusKm * c;

    return distance;
}

function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
}

const parseJsonSafe = (jsonString) => {
    try {
        return JSON.parse(jsonString);
    } catch (error) {
        return null;
    }
};

function createSlug(str) {
    return str.toLowerCase()
        .replace(/[^\w\s-]/g, '')
        .replace(/\s+/g, '-')
        .replace(/^-+|-+$/g, '');
}

//listening to the event to change the layout of the list
window.addEventListener('ChangePage', function (event) {
    StoreLocator.paginateStoreResults();
});


