import {debounce} from "./debounce";

const scrollObserver = ({
                            items,
                            rootMargin = "-150px 0px",
                            threshold = 0.05,
                            targetClass = "animated",
                            instance = {}
                        }) => {
    if (!items || items.length === 0) {
        return;
    }
    let options = {
        root: null,
        rootMargin,
        threshold,
    };

    let observer = new IntersectionObserver(beTouching, options);
    items.forEach(item => observer.observe(item));

    // Cookiebot
    const hasCookiebotMarketing = Cookiebot?.consent?.marketing;

    function beTouching(entries, ob) {
        entries.forEach(entry => {
            if (entry.target.matches('.marquee__inner')) {
                if (entry.isIntersecting) {
                    entry.target.classList.remove('paused')
                } else {
                    entry.target.classList.add('paused')
                }
            } else if (
                entry.target.classList.contains('vimeo-iframe')
                && entry.target.hasAttribute('data-video-id')
            ) {
                // Vimeo options
                let vimeoOptions = {
                    badge: 0,
                };
                const options = entry.target.getAttribute('data-vimeo-options')
                if (options) {
                    vimeoOptions = {...vimeoOptions, ...JSON.parse(options)}
                }

                const hasIframe = entry.target.querySelector('iframe');
                let queryString = new URLSearchParams(vimeoOptions).toString();
                queryString += entry.target.classList.contains('vimeo-background') ? '?h=71f0152c4d&badge=0&autopause=0&player_id=0&app_id=58479' : '';
                if (entry.isIntersecting && !hasIframe) {
                    let iframe = document.createElement("iframe");
                    const src = `https://player.vimeo.com/video/${entry.target.dataset.videoId}?${queryString}`;
                    if (hasCookiebotMarketing) {
                        iframe.setAttribute('src', src);
                        iframe.setAttribute('data-cookieconsent', 'marketing');
                    } else {
                        iframe.setAttribute('data-cookieblock-src', src);
                        iframe.setAttribute('data-cookieconsent', 'marketing');
                    }
                    entry.target.appendChild(iframe);
                }
            } else if (entry.target.classList.contains('pause-onLeave') && entry.target.tagName === "VIDEO") {
                // Video playing control
                if (entry.isIntersecting) {
                    entry.target.play();
                } else {
                    entry.target.pause();
                }
            } else if (entry.target.matches('.carousel-adaptive-height.slick-initialized')) {
                // Carousel technical specifications
                const img = document.querySelector(".carousel-adaptive-height .carousel-first-image img");
                document.querySelector(".carousel-adaptive-height .slick-list.draggable").style.height = img.getBoundingClientRect().height + "px";
                ob.unobserve(entry.target);
            } else if (
                entry.target.matches('.wide-center-carousel')
                && typeof $('.wide-center-carousel').slick !== 'undefined'
            ) {
                const $wideCenterCarousel = $('.wide-center-carousel');
                entry.isIntersecting
                    ? $wideCenterCarousel.slick('slickPlay')
                    : $wideCenterCarousel.slick('slickPause');
            } else if (entry.isIntersecting) {
                entry.target.classList.add(targetClass);
                ob.unobserve(entry.target);
            }
        });
    }

    const handleParallaxScroll = () => {
        let parallaxItemsSelector = `.parallax.${targetClass} [data-parallax]`;
        let parallaxItems = document.querySelectorAll(parallaxItemsSelector);
        if (parallaxItems.length) {
            parallaxItems.forEach((item) => {
                let dataParallax = item.getBoundingClientRect().top / item.dataset.parallax + 'px';
                item.style.transform = 'translate3d(0, ' + dataParallax + ', 0)';
            });
        }
    }
    handleParallaxScroll();
    const onScroll = debounce(() => handleParallaxScroll(), 6);
    window.addEventListener("scroll", onScroll);
}

export default scrollObserver;
