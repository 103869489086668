export const startPageOnTop = () => {
    // Force to start page on top
    if (history.scrollRestoration) {
        history.scrollRestoration = 'manual';
    } else {
        window.onbeforeunload = function () {
            window.scroll({top: 0, left: 0});
        }
    }
}

/**
 * ScrollTop
 * @param el
 * @param value
 * @returns {*}
 */
export function scrollTop(el, value) {
    if (value === undefined) {
        return el.pageYOffset;
    } else {
        if (el === window || el.nodeType === 9) {
            el.scrollTo(el.pageXOffset, value);
        } else {
            el.pageYOffset = value;
        }
    }
}

/**
 * Helper : trigger
 * @param el
 * @param eventType
 */
export function trigger(el, eventType) {
    if (typeof eventType === 'string' && typeof el[eventType] === 'function') {
        el[eventType]();
    } else {
        const event =
            typeof eventType === 'string'
                ? new Event(eventType, {bubbles: true})
                : eventType;
        el.dispatchEvent(event);
    }
}

/**
 * Helper : parents
 * @param el
 * @param selector
 * @returns {*[]}
 */
export function parents(el, selector) {
    const parents = [];
    while ((el = el.parentNode) && el !== document) {
        if (!selector || el.matches(selector)) parents.push(el);
    }
    return parents;
}

/**
 * Calculate 100vh
 */
export const getScreenHeight = () => document.documentElement.clientHeight;
