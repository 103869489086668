import {matchMediaXlDown} from "./mediaQuery";
import {parents} from "./utilities";

/**
 * Main Nav handler (desktop & mobile)
 * @type {NodeListOf<Element>}
 */
const mainNavHandler = (mainNav, mainNavLinks) => {
    const navItemShop = mainNav.querySelector('.nav-item--shop');
    const shopNavLinks = navItemShop?.querySelectorAll('.nav-link');
    const shopSearchBannerContainer = navItemShop?.querySelector('.shop-search-banner-container');

    const getMegaDropdown = (item) => {
        return item.parentNode.querySelector('.mega-dropdown');
    }

    const getMegaDropdownOverlayChildren = (item) => {
        return item.parentNode.querySelector('.mega-dropdown__overlay-children') || item.parentNode.querySelector('.mega-dropdown__overlay-children-level2');
    }

    const closeMegaDropdownOverlayChildren = () => {
        navItemShop?.querySelectorAll('.mega-dropdown__list .nav-link')?.forEach(navLink => navLink.classList.remove('active'));
        navItemShop?.querySelector('.mega-dropdown__overlay-children.show')?.classList.remove('show');
        navItemShop?.querySelector('.mega-dropdown__overlay-children-level2.show')?.classList.remove('show');
        navItemShop?.querySelectorAll('.mega-dropdown__list:not(.mega-dropdown__list--index-0)')?.forEach(list => list.classList.remove('invisible'));
        shopSearchBannerContainer?.classList.remove('invisible');
    }

    const closeMegaDropdownOverlayChildrenLevel2 = () => {
        navItemShop?.querySelectorAll('.nav-link.has-overlay-children-level2')?.forEach(navLink => navLink.classList.remove('active'));
        navItemShop?.querySelector('.mega-dropdown__overlay-children-level2.show')?.classList.remove('show');
    }

    const closeMegaDropdown = () => {
        document.body.classList.remove('mega-dropdown-shown');
        mainNav.querySelector('.nav-link--main.active')?.classList.remove('active');
        mainNav.querySelector('.mega-dropdown.show')?.classList.remove('show');
        closeMegaDropdownOverlayChildren();
    }

    const activateSearchBannerAttributesTab = (target) => {
        if (!target || !document.body.classList.contains('accessories')) return;
        getMegaDropdown(target).querySelector('.search-banner-tab__button[data-type="accessory"]')?.click();
    }

    mainNavLinks.forEach(mainNavLink => {
        /**
         * Handle mouseenter for desktop
         */
        mainNavLink.addEventListener('mouseenter', (e) => {
            const target = e.currentTarget;
            if (!target.classList.contains('has-children')) {
                closeMegaDropdown();
            } else {
                const mainNavLinkActive = mainNav.querySelector('.nav-link--main.active');
                if (mainNavLinkActive) {
                    mainNavLinkActive.classList.remove('active');
                    if (getMegaDropdown(mainNavLinkActive)) {
                        getMegaDropdown(mainNavLinkActive).classList.remove('show');
                        closeMegaDropdownOverlayChildren();
                    }
                }
                target.classList.add('active');
                if (getMegaDropdown(target)) {
                    getMegaDropdown(target).classList.add('show');
                    if (!document.body.classList.contains('mega-dropdown-shown')) {
                        document.body.classList.add('mega-dropdown-shown');
                    }
                    if (getMegaDropdown(target).classList.contains('mega-dropdown--search')) {
                        activateSearchBannerAttributesTab(target);
                    }
                }
                const navbarUnderlay = document.querySelector('.navbar-underlay');
                if (navbarUnderlay && getMegaDropdown(target)) {
                    navbarUnderlay.style.height = `${getMegaDropdown(target).offsetHeight}px`;
                }
            }
        });
        /**
         * Handle click for mobile
         */
        if (matchMediaXlDown.matches) {
            mainNavLink.addEventListener('click', (e) => {
                const target = e.currentTarget;
                if (getMegaDropdown(target) && target.classList.contains('has-children')) {
                    e.preventDefault();
                    getMegaDropdown(target).classList.add('show');
                }
                if (getMegaDropdown(target)?.classList.contains('mega-dropdown--search')) {
                    activateSearchBannerAttributesTab(target);
                }
            });
        }
    });

    mainNav.addEventListener('mouseleave', () => {
        closeMegaDropdown();
    });

    /**
     * Mobile Navbar Toggler
     * @type {Element}
     */
    const mobileNavbarToggler = document.querySelector('#mobile-navbar-toggler');
    if (mobileNavbarToggler) {
        mobileNavbarToggler.addEventListener('click', (e) => {
            e.preventDefault();
            const ariaExpanded = e.currentTarget.getAttribute('aria-expanded');
            ariaExpanded === "false" ?
                e.currentTarget.setAttribute('aria-expanded', 'true') :
                e.currentTarget.setAttribute('aria-expanded', 'false');
            const navTargetSelector = e.currentTarget.dataset.target;
            if (navTargetSelector) {
                const navTarget = document.querySelector(navTargetSelector);
                navTarget?.classList.toggle('show');
                if (navTargetSelector === '#main-nav') {
                    document.body.classList.add('main-nav-shown');
                }
            }
        });
    }

    /**
     * Shop nav links
     */
    if (shopNavLinks?.length) {
        shopNavLinks.forEach(shopNavLink => {
            shopNavLink.addEventListener('mouseenter', (e) => {
                if (e.currentTarget.classList.contains('has-overlay-children')) {
                    closeMegaDropdownOverlayChildren();
                    e.currentTarget.classList.add('active');
                    const megaDropdownOverlayChildren = e.currentTarget.nextElementSibling;
                    megaDropdownOverlayChildren?.classList.add('show');
                    shopSearchBannerContainer?.classList.add('invisible');
                    navItemShop?.querySelectorAll('.mega-dropdown__list:not(.mega-dropdown__list--index-0)')?.forEach(list => list.classList.add('invisible'));
                } else if (e.currentTarget.classList.contains('has-overlay-children-level2')) {
                    closeMegaDropdownOverlayChildrenLevel2();
                    e.currentTarget.classList.add('active');
                    const megaDropdownOverlayChildren = e.currentTarget.nextElementSibling;
                    megaDropdownOverlayChildren?.classList.add('show');
                } else if (parents(e.currentTarget, '.mega-dropdown__overlay-children').length === 0) {
                    closeMegaDropdownOverlayChildren();
                }
            });

            const megaDropdownInner = navItemShop.querySelector('.mega-dropdown__inner');
            if (megaDropdownInner) {
                megaDropdownInner.addEventListener('mouseleave', (e) => {
                    closeMegaDropdownOverlayChildren();
                });
            }

            /**
             * Handle click for mobile
             */
            if (matchMediaXlDown.matches) {
                shopNavLink.addEventListener('click', (e) => {
                    const target = e.currentTarget;
                    if (
                        target.classList.contains('has-overlay-children')
                        || target.classList.contains('has-overlay-children-level2')
                    ) {
                        e.preventDefault();
                        getMegaDropdownOverlayChildren(target).classList.add('show');
                    }
                });
            }
        })
    }

    /**
     * Search Accessories Tab trigger
     * @type {NodeListOf<Element>}
     */
    const jsTriggerSearchAccessoriesTabs = document.querySelectorAll('.js-search-accessories-tab-trigger');
    if (jsTriggerSearchAccessoriesTabs.length) {
        jsTriggerSearchAccessoriesTabs.forEach(jsTriggerSearchAccessoriesTab => {
            jsTriggerSearchAccessoriesTab.addEventListener('click', (e) => {
                e.preventDefault();
                const searchBanners = parents(e.currentTarget, '.search-banner-container');
                searchBanners[0]?.querySelector('.search-banner-tab__button[data-type="accessory"]')?.click();
            })
        });
    }
}

export default mainNavHandler;
