import select2 from 'select2';
// Init select2
select2();

export const allowSelectScroll = () => {
    const selects = document.querySelectorAll(".select2-selection.select2-selection--single");
    if (selects.length) {
        selects.forEach((el) => {
            el.addEventListener('click', () => {
                const select = document.querySelector(`.select2-results__options`);
                select?.setAttribute('data-lenis-prevent', '');
            })
        })
    }
}

// Default select
export const initDefaultSelect = () => {
    const $defaultSelects = $('.js-select2');
    if ($defaultSelects) {
        $defaultSelects.each(function () {
            $(this).select2({
                theme: "bootstrap-5",
            });
        });
    }

    allowSelectScroll()
}

function formatState(state) {
    if (!state.id || state.element.getAttribute('default')) return state.text;

    const $state = $(
        `<div class="d-flex align-items-center gap-2">
            <figure class="media-wrap icon--xxl flex-shrink-0">
                <img src="${state.element.getAttribute('data-img')}" class="img-fluid" />
            </figure>

            <div>
                <div class="fs-base mb-1">
                    ${state.text}
                </div>

                <div class="fs-xs text-gray-600">
                    ${state.element.getAttribute('data-category')}
                </div>
            </div>
        </div>`
    );
    return $state;
}

export const productSearchInput = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        templateResult: formatState
    });
}

export const productWidthSelect = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
    $select.on('change', function (e) {
        var data = $(e.currentTarget).select2("val");
        Livewire.dispatch('setWidth', {widthId: data});
    });
}

export const productFinishSelect = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
    $select.on('change', function (e) {
        var data = $(e.currentTarget).select2("val");
        Livewire.dispatch('setColor', {colorId: data});
    });
}

export const downloadWidthSelect = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
    $select.on('change', function (e) {
        var data = $(e.currentTarget).select2("val");
        Livewire.dispatch('setWidth', {widthId: data});
    });
}

export const downloadFinishSelect = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
    $select.on('change', function (e) {
        var data = $(e.currentTarget).select2("val");
        Livewire.dispatch('setColor', {colorId: data});
    });
}

export const compatibleAccessoriesWidthSelect = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
    $select.on('change', function (e) {
        var data = $(e.currentTarget).select2("val");
        Livewire.dispatch('setWidth', {widthId: data});
    });
}

export const compatibleAccessoriesFinishSelect = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
    $select.on('change', function (e) {
        var data = $(e.currentTarget).select2("val");
        Livewire.dispatch('setColor', {colorId: data});
    });
}

export const countrySelect = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectCountry_2 = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}
export const selectCountry_3 = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectCountry_4 = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectProvince = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectProvince_2 = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectProvince_3 = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectCity = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectCity_2 = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectCity_3 = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectUserType = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const selectRequestType = ($select) => {
    if (!$select) {
        return;
    }

    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const modalProductType = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}

export const modalProductCode = ($select) => {
    if (!$select) {
        return;
    }
    $select.select2({
        theme: "bootstrap-5",
        /*ajax: {
            url: $someURL,
            dataType: 'json'
        }*/
    });
}
