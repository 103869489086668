import {
    compatibleAccessoriesFinishSelect, compatibleAccessoriesWidthSelect,
    downloadFinishSelect, downloadWidthSelect,
    productFinishSelect, productWidthSelect
} from "../selects";
import jQuery from 'jquery';

export default function productDetail() {
    document.addEventListener('livewire:init', () => {
        Livewire.on('reloadJS', (event) => {
            setTimeout(() => {
                activateSelects();
            }, 500)
        });
        Livewire.on('searchDetailEventAnalytics', (params) => {
            let data = params[0];
            data['event'] = 'ga4_event';
            const event = new CustomEvent('DataLayerEventPush', {
                detail: {
                    data: data,
                },
            });
            window.dispatchEvent(event);
        });
        Livewire.on('livewire-carousel-loaded', (event) => {
            import('./../carousels').then(module => {
                document.querySelectorAll('.shop-carousel').forEach(shopCarouselItem => {
                    module.shopCarousel(shopCarouselItem);
                })
            });
        })
    });

    (function ($) {
        activateSelects();
        // $('.spider-pagination-products').hide();
    })(jQuery);
}

function activateSelects() {
    const $productWidthSelect = $("#selectProductWidth");
    if ($productWidthSelect) {
        productWidthSelect($productWidthSelect);
    }

    const $productFinishSelect = $("#selectProductFinish");
    if ($productFinishSelect) {
        productFinishSelect($productFinishSelect);
    }

    const $downloadWidthSelect = $("#selectDownloadWidth");
    if ($downloadWidthSelect) {
        downloadWidthSelect($downloadWidthSelect);
    }

    const $downloadFinishSelect = $("#selectDownloadFinish");
    if ($downloadFinishSelect) {
        downloadFinishSelect($downloadFinishSelect);
    }

    const $compatibleAccessoriesWidthSelect = $("#selectCompatibleAccessoriesWidth");
    if ($compatibleAccessoriesWidthSelect) {
        compatibleAccessoriesWidthSelect($compatibleAccessoriesWidthSelect);
    }

    const $compatibleAccessoriesFinishSelect = $("#selectCompatibleAccessoriesFinish");
    if ($compatibleAccessoriesFinishSelect) {
        compatibleAccessoriesFinishSelect($compatibleAccessoriesFinishSelect);
    }
}
