import jQuery from "jquery";
import Modal from 'bootstrap/js/dist/modal';
import { initDefaultSelect } from "../../selects";

export default function () {
    document.addEventListener('livewire:init', () => {
        Livewire.on('address-deleted', (event) => {
            // const myModal = new Modal(document.getElementById('address-delete'))
            // myModal.show()
        });
        Livewire.on('address-saved', (event) => {
            const myModal = new Modal(document.getElementById('address-confirmation'))
            myModal.show()
        });
        Livewire.on('address-loaded', () => setTimeout(() => initDefaultSelect()));

        let container = $('#address-edit');
        let select = $('select[id="address.countryCodeV2"]');
        let model = select.attr('wire:model');

        select.on('select2:select', function (e) {
            let component = container.attr('wire:id');
            let val = select.select2("val")
            Livewire.find(component).set(model, val, false);
        });
    });
}
