export default function accessories() {
    document.addEventListener('livewire:init', () => {

        Livewire.on('accessoriesEventAnalytics', (params) => {
            let data = params[0];
            const event = new CustomEvent('DataLayerEventPush', {
                detail: {
                    data: data,
                },
            });
            window.dispatchEvent(event);
        });
    });
}
