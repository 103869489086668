export default function () {
    $(document).ready(function () {
        const $stickyDiv = $('#addtocart-banner');
        const $triggerSection = $('#additional-info');
        const $firstSection = $('#accessory-main').first();

        $(window).on('scroll', function () {
            const triggerOffset = $triggerSection.offset().top;
            const firstSectionOffset = $firstSection.offset().top;
            const scrollPosition = $(window).scrollTop();
            const windowHeight = $(window).height();

            if (scrollPosition + windowHeight > triggerOffset) {
                $stickyDiv.show();
            } else {
                $stickyDiv.hide();
            }

            if (scrollPosition < firstSectionOffset + $firstSection.outerHeight()) {
                $stickyDiv.hide();
            }
        });

        // counter prodotti
        const $btnPlus = $('.btn--plus');
        if ($btnPlus) {
            $btnPlus.on('click', function () {
                const $quantity = $(this).parent().children('.quantity');
                const $quantityValue = parseInt($quantity.val(), 10);
                const $quantityMax = $quantity.attr('max');
                $(this).removeAttr('disabled')
                if (($quantityValue + 1) > 0) {
                    $(this).parent().find('.btn--minus').removeAttr('disabled')
                }
                if ($quantityValue == $quantityMax) {
                    $(this).attr('disabled', 'disabled')
                }
                if ($quantityValue < $quantityMax) {
                    $quantity.attr('value', $quantityValue + 1);
                    $quantity.val($quantityValue + 1);
                    if (($quantityValue + 1) == $quantityMax) {
                        $(this).attr('disabled', 'disabled')
                    }
                } else {
                    $(this).attr('disabled', 'disabled')
                }
            })
        }
        const $btnMinus = $('.btn--minus');
        if ($btnMinus) {
            $btnMinus.on('click', function () {
                const $quantity = $(this).parent().children('.quantity');
                const $quantityMax = $quantity.attr('max');
                const $quantityValue = parseInt($quantity.val(), 10);
                $(this).removeAttr('disabled')
                if (($quantityValue - 1) < $quantityMax) {
                    $(this).parent().find('.btn--plus').removeAttr('disabled')
                }
                if ($quantityValue > 0) {
                    $quantity.attr('value', $quantityValue - 1);
                    $quantity.val($quantityValue - 1);

                    if (($quantityValue - 1) === 0) {
                        $(this).attr('disabled', 'disabled')
                    }
                } else {
                    $(this).attr('disabled', 'disabled')
                }
            })
        }
        // fine counter prodotti
    });
}
