import {carouselRevealAnimation, shopOrdersCarousel} from "../../carousels";

export default function () {
    document.addEventListener('livewire:init', () => {
        Livewire.on('admin-order-loaded', (data) => {
            setTimeout(() => {
                const shopOrdersCarouselItems = document.querySelectorAll('.shop-orders-carousel');

                if (shopOrdersCarouselItems.length) {
                    import('../../carousels').then(module => {
                        shopOrdersCarouselItems.forEach(shopOrdersCarouselItem => {
                            module.shopOrdersCarousel(shopOrdersCarouselItem);
                        })
                        carouselRevealAnimation()
                    });
                }
            })
        });
    });
}
