import Modal from 'bootstrap/js/dist/modal';
import Offcanvas from 'bootstrap/js/dist/offcanvas';

export default function () {
    document.addEventListener('livewire:init', () => {
        Livewire.on('show-loader-minicart', (event) => {
            document.querySelector('.minicart-loader').style.display = 'flex';
        });

        Livewire.on('show-minicart', (event) => {
            const selector = document.querySelector('#miniCart');
            const miniCart = new Offcanvas(selector);
            selector.addEventListener('shown.bs.offcanvas', event => {
                document.querySelector('.minicart-loader').style.display = 'none';
            })
            miniCart.show();
        });

        Livewire.on('show-modal-minicart', (event) => {
            const selector = document.querySelector('#modal-minicart');
            const modalMiniCart = new Modal(selector);
            selector.addEventListener('shown.bs.modal', event => {
                document.querySelector('.minicart-loader').style.display = 'none';
            })
            modalMiniCart.show();
        });
    });
}
