import Modal from 'bootstrap/js/dist/modal';

export default function () {
    document.addEventListener('livewire:init', () => {
        Livewire.on('wishlist-show-popup', (data) => {
            setTimeout(function () {
                const modal = document.getElementById('modal-' + data[0]);
                document.body.appendChild(modal);
                new Modal(modal).show();
            });
        });
        Livewire.on('wishlist-add', (data) => {
            document.querySelector('.reserved-area-loader').style.display = 'flex';
        });
        Livewire.on('wishlist-remove', (data) => {
            document.querySelector('.reserved-area-loader').style.display = 'flex';
        });
        Livewire.on('wishlist-updated', (data) => {
            document.querySelector('.reserved-area-loader').style.display = 'none';
        });
    });
}
