import {parents} from "./utilities";
import 'slick-carousel';
import scrollObserver from "./scrollObserver";

// CAROUSEL UTILITIES
export const carouselRevealAnimation = () => {
    const carousels = document.querySelectorAll('.carousel.carousel-has-reveal');
    if (carousels && carousels.length) {
        const options = {
            root: null,
            rootMargin: "-150px 0px",
            threshold: 0.05,
        };

        const observer = new IntersectionObserver(beTouching, options);
        carousels.forEach(carousel => observer.observe(carousel));

        function beTouching(entries, ob) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('revealed');
                    ob.unobserve(entry.target);
                }
            });
        }
    }
}

export const categoriesCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    const $carousel = $(carousel);
    $carousel.on('init', (slick) => {
        const slides = slick.currentTarget.querySelectorAll('.slick-slide');

        const slickArrows = slick.currentTarget?.parentNode.querySelectorAll('.slick-arrow');
        const textCarousel = parents(slick.currentTarget, '.text-carousel')[0];
        if (slickArrows.length && textCarousel && slides.length) {
            slickArrows.forEach(slickArrow => {
                slickArrow.addEventListener('click', () => {
                    const currentSlide = [...slides].filter(slide => slide.classList.contains('slick-current'));
                    if (currentSlide[0] && currentSlide[0].dataset.slickIndex > 0) {
                        textCarousel.classList.add('triggered-nav');
                    } else {
                        textCarousel.classList.remove('triggered-nav')
                    }
                    if (parseInt(currentSlide[0].dataset.slickIndex) >= slides.length - 3) {
                        const button = document.getElementsByClassName('slick-next slick-arrow');
                        button[0].classList.add('d-none')
                    } else if (parseInt(currentSlide[0].dataset.slickIndex) < slides.length - 3) {
                        const button = document.getElementsByClassName('slick-next slick-arrow');
                        button[0]?.classList.remove('d-none')
                    }
                });
            });
        }
    });
    $carousel.slick({
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        appendArrows: $carousel[0]?.parentNode.querySelector('.carousel-nav'),
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    swipe: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    swipe: false,
                }
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
        ]
    });
}

export const accessoriesCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    const $carousel = $(carousel);
    $carousel.on('init', (slick) => {
        const slides = slick.currentTarget.querySelectorAll('.slick-slide');
        if (slides.length) {
            slides.forEach(slide => {
                slide.classList.add('has-reveal');
            });
        }
    });
    $carousel.slick({
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        appendArrows: $carousel[0]?.parentNode.querySelector('.carousel-nav'),
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
        ]
    });
}

export const serviceCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    const $carousel = $(carousel);
    $carousel.slick({
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
    });
}

export const certificateCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    const $carousel = $(carousel);
    $carousel.slick({
        dots: true,
        infinite: true,
        speed: 600,
        fade: true,
        arrows: false,
    });
}

export const categoriesFilterCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    const $carousel = $(carousel);
    $carousel.on('init', (slick) => {
        const slides = slick.currentTarget.querySelectorAll('.slick-slide');
        if (slides.length) {
            slides.forEach(slide => {
                slide.classList.add('has-reveal');
            });
        }
    });
    $carousel.slick({
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        appendArrows: $carousel[0]?.parentNode.querySelector('.carousel-nav'),
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 992,
                settings: "unslick",
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
        ]
    });
}

export const productHeroCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    const $carousel = $(carousel);
    $carousel.slick({
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        autoplaySpeed: 1200,
    });
    // Navigation
    const $productHeroCarouselNavButtons = $('.product-hero-carousel__nav-btn');
    const $productHeroCarouselColorName = $('#product-hero-carousel__color-name');
    if ($productHeroCarouselNavButtons) {
        $productHeroCarouselNavButtons.on('click', function (e) {
            e.preventDefault();
            $productHeroCarouselNavButtons.removeClass("active");
            $carousel.slick('slickGoTo', $(this).data('slide'));
            $(this).addClass('active');
            if ($productHeroCarouselColorName) {
                $productHeroCarouselColorName.text($(this).data('color-name'));
            }
        })
    }
}

export const cappeCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    const $carousel = $(carousel);
    $carousel.slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        dots: false,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
    });
}

export const wideCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    const $carousel = $(carousel);
    $carousel.slick({
        infinite: true,
        speed: 1200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    arrows: true,
                    dots: false,
                }
            },
            {
                breakpoint: 0,
                settings: {
                    dots: true,
                }
            },
        ]
    });
}

export const productsSuggestedCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    const $carousel = $(carousel);
    $carousel.slick({
        arrows: false,
        dots: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
        ]
    });
}

export const appFunctionsCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    const $carousel = $(carousel);
    $carousel.slick({
        infinite: true,
        arrows: false,
        speed: 500,
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        responsive: [
            {
                breakpoint: 756,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                }
            },
            {
                breakpoint: 1200,
                settings: 'unslick',
            },

        ]
    });
    $('button[data-bs-toggle="tab"]').on('shown.bs.tab', function (e) {
        e.target
        e.relatedTarget
        $carousel.slick('setPosition');
    });
}

export const singleSlideCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    const $carousel = $(carousel);
    $carousel.slick({
        arrows: false,
        dots: true,
        speed: 500,
        cssEase: 'linear',
        adaptiveHeight: true,
    });
}

export const hoodChoiceCarousel = (carousel) => {
    if (!carousel) return;

    const $carousel = $(carousel);
    $carousel.slick({
        dots: true,
        arrows: false,
        autoplay: true,
        speed: 400,
    });

    const slides = Array.from(document.querySelectorAll('[data-carousel-slide]'));
    slides.filter(slide => parseInt(slide.dataset.carouselSlide) !== 0).forEach(slide => slide.style.transform = 'translateY(100%)');

    $(carousel).on('afterChange', function (event, slick, currentSlide) {
        const currSlide = slides.filter(slide => parseInt(slide.dataset.carouselSlide) === currentSlide);
        currSlide.forEach(slide => slide.style.transform = 'translateY(0)');
        slides.filter(slide => parseInt(slide.dataset.carouselSlide) !== currentSlide).forEach(slide =>
            setTimeout(() => slide.style.transform = 'translateY(100%)', 300)
        )
    });
}

/**
 * Wall (innovation)
 * @param carousel
 */
export const wallCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    const $carousel = $(carousel);
    $carousel.slick({
        autoplay: true,
        autoplaySpeed: 1,
        infinite: true,
        speed: 8000,
        arrows: false,
        cssEase: 'linear',
        pauseOnFocus: false,
        pauseOnHover: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 2,
                }
            },
        ]
    });
}

export const materialCappaCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    const $carousel = $(carousel);
    $carousel.slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: false,
        dots: true,
        speed: 500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    });
}

export const prizesCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    const $carousel = $(carousel);
    $carousel.slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        autoplay: true,
        pauseOnHover: false,
        autoplaySpeed: 1000,
        speed: 1000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            /*{
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },*/
        ]
    });
}

export const verticalMarqueeCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    const $carousel = $(carousel);
    $carousel.slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        vertical: true,
        dots: false,
        infinite: true,
        speed: 1000,
        cssEase: 'linear',
    });
}

export const wideCenterCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    const $carousel = $(carousel);
    $carousel.slick({
        centerMode: true,
        centerPadding: '60px',
        speed: 1600,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false, // set to true by Scroll Observer in viewport
        autoplaySpeed: 3000,
        infinite: false,
        appendArrows: $carousel.parent().find('.wide-center-carousel-navigation'),
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    arrows: true,
                    dots: false,
                }
            },
            {
                breakpoint: 0,
                settings: {
                    dots: true,
                }
            },
        ]
    });
}

export const accessoryGalleryCarousel = (carousel) => {
    if (!carousel) {
        return;
    }
    $('.slider-for').slick({
        slidesToShow: 1,
        arrows: false,
        asNavFor: '.slider-nav',
        vertical: false,
        fade: true,
        autoplay: false,
        centerMode: false
    });
    $('.slider-nav').slick({
        slidesToShow: 3,
        arrows: false,
        asNavFor: '.slider-for',
        vertical: true,
        focusOnSelect: true,
        autoplay: false,
        centerMode: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    vertical: false,
                }
            }
        ]
    });
}

export const shopCarousel = (carousel) => {
    if (!carousel) return;

    // xs: 0,
    //     sm: 576px,
    //     md: 768px,
    //     lg: 992px,
    //     xl: 1200px,
    //     xxl: 1440px,

    const $carousel = $(carousel);
    $carousel.slick({
        dots: true,
        arrows: false,
        infinite: false,
        autoplay: false,
        slidesToShow: $carousel.data('slides') ?? 2,
        slidesToScroll: $carousel.data('slides') ?? 2,
        speed: 400,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: $carousel.data('slides-sm') ?? 2,
                    slidesToScroll: $carousel.data('slides-sm') ?? 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: $carousel.data('slides-md') ?? 3,
                    slidesToScroll: $carousel.data('slides-md') ?? 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: $carousel.data('slides-lg') ?? 4,
                    slidesToScroll: $carousel.data('slides-lg') ?? 4,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    arrows: true,
                    slidesToShow: $carousel.data('slides-xl') ?? 5,
                    slidesToScroll: $carousel.data('slides-xl') ?? 5,
                }
            }
        ]
    });
}

export const shopHomeCarousel = (carousel) => {
    if (!carousel) return;

    const $carousel = $(carousel);
    $carousel.slick({
        dots: true,
        arrows: false,
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        speed: 400,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    dots: false,
                    arrows: true,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            }
        ]
    });
}

export const shopOrdersCarousel = (carousel) => {
    if (!carousel) return;

    const $carousel = $(carousel);
    $carousel.slick({
        dots: false,
        arrows: true,
        infinite: false,
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        speed: 400,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                }
            }
        ]
    });
}


/*export const productsCarousel = ($productsCarousel) => {
    if ($productsCarousel) {
        $productsCarousel.slick({
            infinite: false,
            speed: 300,
            arrows: false,
            dots: true,
            dotsClass: 'products-carousel__nav mt-5',
            responsive: [
                {
                    breakpoint: 999999,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    }
                },
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
            ]
        });
    }
}*/

/*export const productGallery = ($productGallery) => {
    if ($productGallery) {
        $productGallery.slick({
            infinite: false,
            speed: 300,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            dotsClass: 'product-gallery__nav',
            responsive: [
                {
                    breakpoint: 999999,
                    settings: "unslick",
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        });
    }
}*/

/*export const relatedProducts = ($relatedProducts) => {
    if ($relatedProducts) {
        $relatedProducts.slick({
            infinite: false,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            dotsClass: 'related-products__nav mt-5',
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
            ]
        });
    }
}*/

/*export const familyCarousel = ($familyCarousel) => {
    if ($familyCarousel) {
        $familyCarousel.slick({
            infinite: false,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            arrows: false,
            dots: true,
            dotsClass: 'family-carousel__nav mt-5',
            responsive: [
                {
                    breakpoint: 999999,
                    settings: {
                        centerMode: true,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        centerMode: false,
                    }
                }
            ]
        });
    }
}*/

